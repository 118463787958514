<template>
  <div class="extendedcalendar">
    <div class="extendedcalendar__menu">
      <div class="extendedcalendar__menu__title" @click="clicktoScroll">Buscar por equipo</div>
      <div class="extendedcalendar__menu__panel-teams">
        <div class="extendedcalendar__menu__panel-teams__cont-teams">
          <div
            :class="allfilter ? 'selectbtn' : ''"
            class="extendedcalendar__menu__panel-teams__cont-teams__button-all"
            @click="clickFilterTeamAll()"
          >
            Todos
          </div>

          <template v-for="(team, index) in teamsmenu">
            <div
              :key="index"
              class="extendedcalendar__menu__panel-teams__cont-teams__btn-team"
              :class="team.id == teamFilter ? 'selectteam' : ''"
              @click="clickFilterTeam(team.id)"
            >
              <img :src="team.logo" />
            </div>
          </template>
        </div>
      </div>
      <div class="extendedcalendar__menu__sep"></div>
    </div>
    <!--    <div class="sepfixed"></div>-->

    <template v-for="(matchdaydata, index) in fullTournamentSchedule">
      <span :key="index">
        <div class="extendedcalendar__label-matchday" v-if="allfilter || getExistGame(matchdaydata)">
          <span :class="index" :ref="'jornada' + index">
            {{ getMatchDay(index)[0] }}&nbsp;&nbsp;
            <span v-if="getMatchDay(index)[1] != 'none'" class="extendedcalendar__label-matchday__medium">
              · {{ getMatchDay(index)[1] }}
            </span>
          </span>
        </div>
        <template v-for="gamesByDate in matchdaydata">
          <template v-for="gameData in gamesByDate.games">
            <ExtendedMatch
              :key="gameData.game_id"
              :data="gameData"
              v-if="
                teamFilter == gameData.home_team.team_id || teamFilter == gameData.visiting_team.team_id || allfilter
              "
            />
          </template>
        </template>
      </span>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { connectSocket } from '@/utils/socketConnection';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { regularLeague, cup, cupLegacy } from '@/utils/play-off-abbreviations';
export default {
  // el: '.main-container',
  name: 'ExtendedCalendar',
  components: {
    ExtendedMatch: () => import('@/components/Sections/Calendar/ExtendedMatch'),
    // NavBarFree: () => import('@/components/NavBars/NavBarFree'),
  },
  props: {
    awaitForSeasons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lastSeasonsIds: [],
      areNewSeasons: false,
      fullTournamentSchedule: {},
      tournamentScheduleKeys: [],
      userTeamGames: [],
      selectedRound: 0,
      teamFilter: 0,
      selectedRoundKeyIndex: 0,
      allfilter: true,
      updateC: 0,
      loadingFlag: true,
      tournamentsUrl: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments' /*--*/,
      gamesUrl: 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason?',
    };
  },
  watch: {
    arraySeasonsByRegularSeason: {
      immediate: true,
      deep: true,
      handler: function(newValue) {
        if (newValue && newValue.length && this.awaitForSeasons) {
          this.teamFilter = 0;
          this.allfilter = true;
          this.areNewSeasons = true;
          this.lastSeasonsIds = newValue.map(season => season.id);
          this.createFullTournamentScheme(newValue, true);
        }
      },
    },
    closestRound(newValue) {
      this.selectedRoundKeyIndex = this.tournamentScheduleKeys.findIndex(key => key === newValue);
      this.selectedRound = newValue;
      setTimeout(() => {
        this.clicktoScroll();
      }, 1000);
    },
  },
  computed: {
    ...mapState('leagueGeneral', ['objectTournamentSelected', 'objectSeasonSelected']),
    ...mapGetters('leagueGeneral', ['arraySeasonsByRegularSeason']),
    getTournamentSelected() {
      return this.objectTournamentSelected ? this.objectTournamentSelected.id : 0;
    },
    seasonsBySelectedSeason() {
      let seasonsBySelectedSeason = null;
      seasonsBySelectedSeason = this.seasonsByRegularSeason.get(Number(this.getSeasonSelected));
      return seasonsBySelectedSeason || [];
    },
    closestRound() {
      return this.userTeamGames && this.userTeamGames.length
        ? this.getUserTeamClosestRound(this.fullTournamentSchedule)
        : this.getNormalClosestRound(this.fullTournamentSchedule);
    },
    isCup() {
      return this.objectTournamentSelected && this.objectTournamentSelected.tournament_type === 1;
    },
    isCupLegacy() {
      return this.objectSeasonSelected === 881;
    },

    teamsmenu() {
      let teams = [];
      if (Object.keys(this.fullTournamentSchedule).length > 0) {
        for (const key in this.fullTournamentSchedule) {
          for (const gamekey in this.fullTournamentSchedule[key]) {
            for (let index = 0; index < this.fullTournamentSchedule[key][gamekey].games.length; index++) {
              const element = this.fullTournamentSchedule[key][gamekey].games[index];
              const indexV = teams.findIndex(function(item) {
                return item.id === element.visiting_team.team_id;
              });
              if (indexV === -1) {
                teams.push({
                  id: element.visiting_team.team_id,
                  logo: element.visiting_team.logo,
                  name: element.visiting_team.colloquial_name,
                });
              }
              const indexH = teams.findIndex(function(item) {
                return item.id === element.home_team.team_id;
              });
              if (indexH === -1) {
                teams.push({
                  id: element.home_team.team_id,
                  logo: element.home_team.logo,
                  name: element.home_team.colloquial_name,
                });
              }
            }
          }
        }
      }
      teams.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return teams;
    },
  },
  methods: {
    ...mapMutations('nextGeneral', ['prepareCleanAllStatistics']),
    /*--*/
    async fetchTournaments(retries) {
      try {
        const { data } = await axios.get(this.tournamentsUrl);
        return data;
      } catch (e) {
        if (retries > 0) {
          return this.fetchTournaments(retries - 1);
        }
        throw new Error(e);
      }
    },
    /*--*/
    getExistGame(games) {
      let value = false;
      if (!this.allfilter) {
        for (let index = 0; index < games.length; index++) {
          for (let index2 = 0; index2 < games[index].games.length; index2++) {
            if (
              this.teamFilter == games[index].games[index2].home_team.team_id ||
              this.teamFilter == games[index].games[index2].visiting_team.team_id
            ) {
              value = true;
            }
          }
        }
      } else {
        value = true;
      }
      return value;
    },
    clicktoScroll() {
      let indexmore = this.selectedRoundKeyIndex + 1;
      if (this.selectedRoundKeyIndex == 23 || (this.getTournamentSelected == 33 && this.selectedRoundKeyIndex == 7)) {
        indexmore = 1;
      }
      var valM = '';
      if (this.getTournamentSelected == 33) {
        switch (indexmore) {
          case 1:
            valM = 'jornadaJornada 1';
            break;
          case 2:
            valM = 'jornadaJornada 2';
            break;
          case 3:
            valM = 'jornadaJornada 3';
            break;
          case 4:
            valM = 'jornadaOctavos';
            break;
          case 5:
            valM = 'jornadaCuartos';
            break;
          case 6:
            valM = 'jornadaSemifinales';
            break;
          case 7:
            valM = 'jornadaFinal';
            break;
          case 8:
            valM = 'jornadaTercer lugar';
            break;
          default:
            break;
        }
      } else if (this.getTournamentSelected === 1046) {
        switch (indexmore) {
          case 1:
            valM = 'jornadaJornada 1';
            break;
          case 2:
            valM = 'jornadaJornada 2';
            break;
          case 3:
            valM = 'jornadaJornada 3';
            break;
          case 4:
            valM = 'jornadaDieciseisavos';
            break;
          case 5:
            valM = 'jornadaOctavos';
            break;
          case 6:
            valM = 'jornadaCuartos';
            break;
          case 7:
            valM = 'jornadaSemifinales';
            break;
          case 8:
            valM = 'jornadaFinal';
            break;
          case 9:
            valM = 'jornadaTercer lugar';
            break;
          default:
            break;
        }
      } else {
        var match = 'jornada';
        if (indexmore > 17) {
          if (this.getTournamentSelected == 1071 || this.getTournamentSelected == 1) {
            switch (indexmore) {
              case 18:
                valM = 'R1';
                break;
              case 19:
                valM = 'CFI';
                break;
              case 20:
                valM = 'CFV';
                break;
              case 21:
                valM = 'SFI';
                break;
              case 22:
                valM = 'SFV';
                break;
              case 23:
                valM = 'FI';
                break;
              case 24:
                valM = 'FV';
                break;
              default:
                break;
            }
          } else {
            switch (indexmore) {
              case 19:
                valM = 'CFI';
                break;
              case 20:
                valM = 'CFV';
                break;
              case 21:
                valM = 'SFI';
                break;
              case 22:
                valM = 'SFV';
                break;
              case 23:
                valM = 'FI';
                break;
              case 24:
                valM = 'FV';
                break;
              default:
                break;
            }
          }
        } else {
          valM = indexmore;
        }
      }
      var headerOffset = 200;
      let el = this.$refs[(match || '') + valM][0];
      let elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition + document.getElementById('main-container').scrollTop - headerOffset;
      document.getElementById('main-container').scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    },
    clickFilterTeamAll() {
      this.teamFilter = 0;
      this.allfilter = true;
      setTimeout(() => {
        this.clicktoScroll();
      }, 1000);
    },
    getNormalClosestRound(tournamentSchedule) {
      const gameDuration = 110 * 60 * 1000;
      const roundsAndEndOfLastGame = Object.entries(
        Object.entries(tournamentSchedule).reduce((accumulator, [k, v]) => {
          accumulator[k] = [];
          v.forEach(day => {
            day.games.forEach(game => {
              const endOfGame = new Date(game.date_time_utc).getTime() + gameDuration;
              accumulator[k].push(endOfGame);
            });
          });
          return accumulator;
        }, {}),
      );
      if (!roundsAndEndOfLastGame.length) {
        return '1';
      }
      const currentTime = new Date().getTime();
      const closestRound = roundsAndEndOfLastGame.reduce(
        (accumulator, [k, v]) => {
          let timeDifferenceGlobal = null;
          v.forEach(endOfGame => {
            const timeDifference = endOfGame - currentTime;
            if (timeDifference >= 0 && (timeDifferenceGlobal === null || timeDifference < timeDifferenceGlobal)) {
              timeDifferenceGlobal = timeDifference;
            }
          });
          if (
            timeDifferenceGlobal &&
            (accumulator.shortestTime === null || timeDifferenceGlobal < accumulator.shortestTime)
          ) {
            accumulator.round = k;
            accumulator.shortestTime = timeDifferenceGlobal;
          }
          return accumulator;
        },
        { round: '', shortestTime: null },
      );

      return closestRound.shortestTime !== null
        ? closestRound.round
        : roundsAndEndOfLastGame[roundsAndEndOfLastGame.length - 1][0];
    },
    getUserTeamClosestRound(tournamentSchedule) {
      let round = 1;
      if (this.userTeamGames.length) {
        const closestUserTeamGame = this.userTeamGames[0];
        for (const [round, value] of Object.entries(tournamentSchedule)) {
          for (const day of value) {
            const scheduleGame = day.games.find(game => game.game_id === closestUserTeamGame.game_id);
            if (scheduleGame) {
              return round;
            }
          }
        }
      }
      return round;
    },
    clickFilterTeam(id) {
      this.teamFilter = id;
      this.allfilter = false;
      // this.updateC += 1;
      setTimeout(() => {
        this.clicktoScroll();
      }, 300);
    },
    getMatchDay(index) {
      let namebold = '';
      let name = 'none';
      if (!this.isCup) {
        if (index > 0 && index < 18) {
          namebold = 'Jornada ' + index;
        } else {
          if (index == 'R1') {
            namebold = 'Repechaje';
          }
          if (index == 'CFI') {
            namebold = 'Cuartos de Final';
            name = 'Ida';
          }
          if (index == 'CFV') {
            namebold = 'Cuartos de Final';
            name = 'Vuelta';
          }
          if (index == 'SFI') {
            namebold = 'Semifinal';
            name = 'Ida';
          }
          if (index == 'SFV') {
            namebold = 'Semifinal';
            name = 'Vuelta';
          }
          if (index == 'FI') {
            namebold = 'Final';
            name = 'Ida';
          }
          if (index == 'FV') {
            name = 'Ida';
            namebold = 'Final';
            name = 'Vuelta';
          }
        }
      } else {
        namebold = index;
      }

      return [namebold, name];
    },
    async createFullTournamentScheme(seasons, applySeasonsValidation) {
      try {
        this.fullTournamentSchedule = {};
        this.userTeamGames = [];
        let regularSeasonId = 0;
        connectSocket();
        await this.prepareCleanAllStatistics();
        const { seasonsAndGames, seasonsAndTeams } = await this.fetchSeasonsGamesAndTeams(
          seasons.map(season => {
            return season.id;
          }),
          3,
        );
        if (applySeasonsValidation && this.areSeasonsDifferent(seasonsAndGames)) return;
        seasonsAndGames.forEach(seasonAndGames => {
          const { type } = seasons.find(season => season.id === seasonAndGames.season_id);
          seasonAndGames.type = type;
          seasonAndGames.games.forEach(game => {
            game.season_id = seasonAndGames.season_id;
          });
          if (type === 0) {
            regularSeasonId = seasonAndGames.season_id;
          }
        });
        const tournamentGames = {
          seasonsGames: seasonsAndGames,
        };
        const { teams } = seasonsAndTeams.find(seasonAndTeams => seasonAndTeams.season_id === regularSeasonId);
        const seasonsSchedules =
          tournamentGames && tournamentGames.seasonsGames
            ? tournamentGames.seasonsGames.map(seasonAndGames => {
                const schedule = seasonAndGames.games.reduce((accumulator, game) => {
                  const gameTmp = { ...game };
                  gameTmp.dateTimeMillis = new Date(gameTmp.date_time_utc).valueOf();
                  gameTmp.home_team = teams.find(team => team.team_id === game.home_team);
                  gameTmp.visiting_team = teams.find(team => team.team_id === game.visiting_team);
                  if (!accumulator[game.matchday_abbreviation]) {
                    accumulator[game.matchday_abbreviation] = {
                      [game.game_status === 3 ? 'canceled' : game.date]: [gameTmp],
                    };
                  } else if (
                    !accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date]
                  ) {
                    accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date] = [
                      gameTmp,
                    ];
                  } else {
                    accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date].push(
                      gameTmp,
                    );
                  }
                  if (
                    this.gsData &&
                    this.gsData.teamId &&
                    gameTmp.game_status === 4 &&
                    (gameTmp.home_team.team_id === this.gsData.teamId ||
                      gameTmp.visiting_team.team_id === this.gsData.teamId)
                  ) {
                    this.userTeamGames.push(gameTmp);
                  }
                  return accumulator;
                }, {});
                this.userTeamGames.sort((a, b) => a.dateTimeMillis - b.dateTimeMillis);
                for (let [k, v] of Object.entries(schedule)) {
                  schedule[k] = Object.entries(v).map(([k2, v2]) => {
                    return {
                      date: k2,
                      games: v2.sort((a, b) => a.dateTimeMillis - b.dateTimeMillis),
                    };
                  });
                  schedule[k].sort((a, b) => {
                    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                  });
                }
                return {
                  type: seasonAndGames.type,
                  weight: seasonAndGames.type === 0 ? 0 : seasonAndGames.type === 2 ? 1 : 2,
                  schedule,
                };
              })
            : [];
        seasonsSchedules.sort((a, b) => a.weight - b.weight);
        this.fullTournamentSchedule = seasonsSchedules.reduce((accumulator, seasonSchedule) => {
          for (const [k, v] of Object.entries(seasonSchedule.schedule)) {
            if (seasonSchedule.type === 0) {
              if (this.isCup) {
                const roundNumberString = k.replace('M', '');
                const defaultName = `Jornada ${roundNumberString}`;
                if (this.isCupLegacy) {
                  accumulator[cupLegacy[roundNumberString] || defaultName] = v;
                } else {
                  accumulator[cup[k] || defaultName] = v;
                }
              } else {
                accumulator[Number(k.replace('M', '')).toString()] = v;
              }
            } else if (seasonSchedule.type === 2) {
              accumulator[`R${Number(k.replace('PO', ''))}`] = v;
            } else if (seasonSchedule.type === 1) {
              accumulator[regularLeague[k]] = v;
            }
          }
          return accumulator;
        }, {});
        this.tournamentScheduleKeys = Object.keys(this.fullTournamentSchedule);
        setTimeout(() => {
          this.clicktoScroll();
        }, 1000);
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loadingFlag = false;
      }
    },

    areSeasonsDifferent(seasonAndGames) {
      for (const season of seasonAndGames) {
        if (!this.lastSeasonsIds.includes(season.season_id)) {
          return true;
        }
      }
      return false;
    },
    async fetchSeasonsGamesAndTeams(seasons, retries, createdUrl = '') {
      let gamesUrl = createdUrl;
      try {
        if (!gamesUrl) {
          gamesUrl = this.gamesUrl;
          seasons.forEach((seasonId, index) => {
            const prefix = index === 0 ? '' : '&';
            gamesUrl += `${prefix}seasons=${seasonId}`;
          });
        }
        const {
          data: { data, teams },
        } = await axios.get(gamesUrl);
        return { seasonsAndGames: data, seasonsAndTeams: teams };
      } catch (e) {
        if (retries > 0) {
          return this.fetchSeasonsGamesAndTeams(seasons, retries - 1, gamesUrl);
        }
        throw new Error(e);
      }
    },
  },
  /*-- */
  async created() {
    try {
      if (!this.awaitForSeasons) {
        const tournaments = await this.fetchTournaments(3);
        const mxLeague = tournaments.find(tournament => tournament._id === 1);
        const regularSeason = mxLeague.seasons[0];
        const seasons = [{ id: regularSeason.id, type: regularSeason.type }];
        if (mxLeague && mxLeague.tournament) {
          this.$emit('tournament-selected', mxLeague.tournament);
        }
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          seasons.push(
            ...regularSeason.related_seasons.map(relatedSeason => ({ id: relatedSeason.id, type: relatedSeason.type })),
          );
        }
        await this.createFullTournamentScheme(seasons, false);
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  /*-- */
};
</script>

<style lang="scss" scoped>
.sepfixed {
  width: 100%;
  height: 110px;
}
.selectbtn {
  background: #cbee6d !important;
}
.selectteam {
  background: #f0f0ee !important;
}
.margintop0x {
  margin-top: 0px !important;
}
.main-container {
  display: inline-block;
  margin: 0 auto;
  max-width: 2000px;
  padding-bottom: 70px;
  width: 96.2%;
  height: 100%;
  border-radius: 21px;
  position: relative;
  box-shadow: 0 7px 24px 0 rgba(84, 124, 171, 0.12);
  background-color: #ffffff;
}
.extendedcalendar {
  width: 100%;
  max-width: 1247px;
  height: auto;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  &__label-matchday {
    width: 100%;
    max-width: 1247px;
    height: 27px;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 3px;
    font-family: 'Avenir-Pro-Bold';
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    text-align: left;
    color: #132739;

    @media screen and (max-width: 1247px) {
      width: 98%;
    }
    @media screen and (max-width: 580px) {
      width: 96%;
    }
    &__medium {
      font-family: 'Avenir-Medium';
    }
    &:nth-child(1) {
      margin-top: 25px;
    }
    // &:nth-child(2) {
    //   margin-top: 105px;
    // }
    // &:nth-child(3) {
    //   margin-top: 105px;
    // }
  }

  &__menu {
    width: 100%;
    max-width: 1247px;
    height: 115px;
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
    z-index: 2;
    margin: 0 auto;

    background: white;
    @media screen and (max-width: 1247px) {
      width: 98%;
    }
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 580px) {
      width: 96%;
    }
    &__sep {
      width: 100%;
      height: 2px;
      border-top: 2px dotted #afbbcd;
      margin-top: 5px;
    }

    &__title {
      width: 100%;
      font-family: 'Avenir-Pro-Bold';
      font-size: 16px;
      height: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #132839;
    }
    &__panel-teams {
      width: 100%;
      height: 64px;
      margin-top: 10px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      &__cont-teams {
        min-width: 1247px;
        display: inline-flex;
        justify-content: left;
        align-content: center;
        align-items: center;
        margin-top: 1px;
        height: 52px;
        &__button-all {
          width: 95px;
          height: 52px;
          border-radius: 31px;
          border: solid 2px #cbee6d;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 52px;
          letter-spacing: -0.25px;
          text-align: center;
          color: #132839;
          font-family: 'Avenir-Pro-Bold';
          &:hover {
            cursor: pointer;
            background: #cbee6d;
          }
        }
        &__btn-team {
          width: 52px;
          height: 52px;
          display: inline-flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          border: solid 2px #f0f0ee;
          border-radius: 16px;
          margin-left: 12px;
          img {
            width: 39px;
          }
          &:hover {
            cursor: pointer;
            background: #f0f0ee;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #e5e7ec;
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e7ec;
  }
}
</style>
